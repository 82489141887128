// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$startapp-new-primary: mat-palette($mat-indigo);
$startapp-new-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$startapp-new-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$startapp-new-theme: mat-light-theme(
  (
    color: (
      primary: $startapp-new-primary,
      accent: $startapp-new-accent,
      warn: $startapp-new-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($startapp-new-theme);

@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import "~swiper/swiper-bundle";

@-ms-viewport {
  zoom: 1;
  width: device-width;
}
@-o-viewport {
  zoom: 1;
  width: device-width;
}
@viewport {
  zoom: 1;
  width: device-width;
}

:root {
  --main: #8558ff;
  --dark: #0a0d15;
}

body {
  font-family: "NanumSquare", sans-serif;
}

@font-face {
  font-family: "BM";
  src: url(./assets/font/BMDOHYEON_otf.otf);
}

.normal {
  font-weight: 400;
}
.bold {
  font-weight: 700;
}
.bolder {
  font-weight: 800;
}
.light {
  font-weight: 300;
}

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body {
  height: 100%;
  font-size: 18px;
}
// @media screen and (max-width: 1023px) {
//   html,
//   body {
//     font-size: 16px;
//   }
// }

/* You can add global styles to this file, and also import other style files */
/*reset*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
address,
em,
img,
ins,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
audio,
video {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
  font-family: "NanumSquare", "Roboto", sans-serif;
  word-break: keep-all;
  //스크롤바 없앰
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
}
//스크롤바 스타일
// ::-webkit-scrollbar {
//   width: 14px;
//   height: 18px;
// }

// ::-webkit-scrollbar-thumb {
//   height: 6px;
//   border: 4px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   background-color: rgba(0, 0, 0, 0.2);
//   -webkit-border-radius: 7px;
//   -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
//     inset 1px 1px 0px rgba(0, 0, 0, 0.05);
// }

// ::-webkit-scrollbar-button {
//   display: none;
//   width: 0;
//   height: 0;
// }

// ::-webkit-scrollbar-corner {
//   background-color: transparent;
// }

body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
main,
footer,
header,
hgroup,
menu,
nav,
section {
  box-sizing: border-box;
  display: block;
}
ul,
li,
dl,
dd,
dt {
  box-sizing: border-box;
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: #000;
  box-sizing: border-box;
}
input,
select,
button {
  vertical-align: middle;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-tap-highlight-color: transparent;
}
small {
  font-size: 80%;
}
img {
  border-style: none;
  display: block;
}

address {
  font-style: normal;
}
button,
input,
textarea,
select,
a {
  border: 0;
  outline: none;
  resize: none;
  box-sizing: border-box;
  font-family: "NanumSquare", sans-serif;
}

/** Correct the inability to style clickable types in iOS and Safari. */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/** * Remove the inner border and padding in Firefox. */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/** * Restore the focus styles unset by the previous rule. */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/** * 1. Add the correct box sizing in IE 10. * 2. Remove the padding in IE 10. */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
/** * Correct the cursor style of increment and decrement buttons in Chrome. */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/** * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari. */
[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/** * Remove the inner padding in Chrome and Safari on macOS. */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/** * 1. Correct the inability to style clickable types in iOS and Safari.
* 2. Change font properties to `inherit` in Safari. */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

button {
  cursor: pointer;
}

/*reset ��*/

#main {
  min-width: 479px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  min-height: 100%;
  z-index: 10;
  // padding-top: 379px;
}

.cf:after {
  content: "";
  clear: both;
  display: block;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.away {
  position: absolute;
  left: -999em;
}

/***라인있는 title****/

.title-area {
  padding: 0 30px 6px 10px;

  h2 {
    font-size: 2.667rem;
  }
}

footer-share {
  position: relative;
  // top: -650px;
  // z-index: -99;
  width: 100%;
  height: auto;
  z-index: 10;
  // transition: top ease-in-out 1s;
  #footer {
    min-width: 479px;
  }
  &.footer-open {
    // top: 0;
    // z-index: 1;
    // transition: top ease-in-out 1s;
  }
}

.none-footer {
  display: none;
  position: absolute;
  left: -9999px;
  top: -9999px;
}

// #mainFooter {
//   position: relative;
//   top: -650px;
//   z-index: -999;
//   will-change: top;
//   transition: top ease-in-out 1s;
//   footer {
//     position: absolute;
//     bottom: 0;
//   }
//   &.footer-open {
//     z-index: 1;
//     top: 0;
//     will-change: top;
//     transition: top ease-in-out 1s;
//   }
// }

.blind {
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.btns-wrap {
  background: #07194e;
  padding: 0 10px;
  .btns-inner {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 0 160px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    max-width: 210px;
    height: 50px;
    font-size: 1rem;
    color: #fff;
    background-repeat: no-repeat;
    background-size: 50px 50px;
    background-color: transparent;
    white-space: pre-line;
    word-break: break-all;
    a {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  .prev-btn {
    left: 0;
    padding-left: 70px;
    background-image: url(./assets/imgs/prev_icon.png);
    background-position: left center;
    padding-right: 0;
    text-align: left;
  }
  .next-btn {
    right: 0;
    padding-right: 70px;
    background-image: url(./assets/imgs/next_icon.png);
    background-position: right center;
    padding-left: 0;
    text-align: right;
  }
  p {
    font-size: 1.333rem;
    color: #fff;
    line-height: 30px;
  }
}

//******텍스트 애니메이션 공통 css

// .txt-ani {
//   transition: clip-path 1s, transform 1s, opacity 1s;
//   clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
//   transform: translate3d(0, 1em, 0) skewY(5deg);
//   transition: all ease-in-out 1s;
// }

// .txt-ani-ac {
//   clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
//   transform: translate3d(0, 0, 0) skewY(0deg);
//   transition: all ease-in-out 1s;
// }

.swiper-pagination {
  width: 100%;
  margin-bottom: 60px;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-right: 50%;
    border-radius: 50%;
    margin-right: 10px;
    background: #ebebeb;
    opacity: 1;
    &:last-child {
      margin-right: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background: #1a3070;
    opacity: 1;
  }
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #586bff;
}

.guide-wrap {
  .swiper-button-prev {
    display: inline-block;
    width: 12px;
    height: 20px;
    background: url(./assets/imgs/slide-prev.png) no-repeat center / contain;
    position: absolute;
    left: calc(50% - 195px);
    &::after {
      display: none;
    }
  }
  .swiper-button-next {
    display: inline-block;
    width: 12px;
    height: 20px;
    background: url(./assets/imgs/slide-next.png) no-repeat center / contain;
    position: absolute;
    right: calc(50% - 195px);
    &::after {
      display: none;
    }
  }
}

// **************** design & marketing & inquiry & portfolio **************** //
// video section
.main-video,
.main-bg {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 200px;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .main-text {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    h2 {
      margin-bottom: 1.12rem;
      font-size: 2rem;
    }
    p {
      font-size: 1.17rem;
      line-height: 1.722rem;
    }
  }
  .scroll-icon {
    position: absolute;
    left: 50%;
    bottom: 50px;
    transform: translate(-50px, -50%);
    padding-top: 77px;
    background: url(assets/imgs/scroll.gif) no-repeat center top / 36px 60px;
    p {
      color: #fff;
      opacity: 0.5;
      font-size: 0.6111rem;
      letter-spacing: 0.3889rem;
      text-indent: 0.3889rem;
      text-align: center;
    }
  }
}
// portfolio header
.detail-header {
  width: 100%;
  height: auto;
  padding: 136px 30px 30px;
  background-color: #0a0d15;
  h1 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 2rem;
    line-height: 2.22rem;
    font-weight: 800;
  }
  p {
    color: #fff;
    font-size: 1.1667rem;
    line-height: 1.722rem;
    font-weight: 300;
  }
}

.appintro-wrap {
  padding: 120px 0 200px;
  .top {
    display: flex;
    align-items: center;
    margin-bottom: 120px;
  }
  .icon {
    width: 180px;
    height: 180px;
    margin-right: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .txt-wrap {
    h3 {
      margin-bottom: 10px;
      font-size: 2rem;
      line-height: 2.22rem;
      &::after {
        content: "";
        display: block;
        width: 60px;
        height: 1px;
        margin-top: 20px;
        background-color: #000;
      }
    }
    p {
      margin-bottom: 10px;
      line-height: 1.778rem;
      word-break: keep-all;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .small {
      line-height: initial;
      font-size: 0.889rem;
      span {
        margin-right: 10px;
        font-size: 0.889rem;
      }
    }
  }
}
.appintro-wrap {
  .swiper-container {
    margin-bottom: 50px;
    overflow: hidden;
    max-width: 1200px;
    li,
    .swiper-slide {
      width: 280px;
      // width: 280px !important;
      max-height: 498px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .big-area {
    display: block;
  }
  .swiper-button-prev:after,
  .swiper-button-next:after {
    content: "";
  }
  // .swiper-button-prev,
  // .swiper-button-next {
  //   display: none;
  // }
  .swiper-button-prev {
    display: block;
    left: 0;
    width: 44px;
    height: 44px;
    background: url(assets/imgs/portfolio_slide_prev.png) no-repeat center /
      44px;
    opacity: 1;
    cursor: pointer;
  }
  .swiper-button-next {
    display: block;
    right: 0;
    width: 44px;
    height: 44px;
    background: url(assets/imgs/portfolio_slide_next.png) no-repeat center /
      44px;
    opacity: 1;
    cursor: pointer;
  }
  .middel-area {
    display: none;
  }
}

.explanation-txt {
  h4 {
    margin-bottom: 20px;
    font-size: 1.333rem;
    font-weight: 800;
    word-break: keep-all;
  }
  p {
    line-height: 1.778rem;
    word-break: keep-all;
  }
}

//푸터설정
.mobile-footer {
  display: none;
}

// #footerWrap {
//   display: block;
// }
@media screen and (max-width: 1199px) {
  .appintro-wrap {
    .big-area {
      display: none;
    }

    .middel-area {
      display: block;
    }
  }
  //   .swiper-button-prev {
  //     display: block;
  //     left: 0;
  //     width: 44px;
  //     height: 44px;
  //     background: url(assets/imgs/portfolio_slide_prev.png) no-repeat center /
  //       44px;
  //     opacity: 1;
  //     cursor: pointer;
  //   }
  //   .swiper-button-next {
  //     display: block;
  //     right: 0;
  //     width: 44px;
  //     height: 44px;
  //     background: url(assets/imgs/portfolio_slide_next.png) no-repeat center /
  //       44px;
  //     opacity: 1;
  //     cursor: pointer;
  //   }
  // }
}

@media screen and (max-width: 1023px) {
  .appintro-wrap {
    .btn-area {
      display: none;
    }
  }

  .project-wrap {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
  .service-txt p {
    display: inline-block;
  }

  .admin-sample-modal {
    max-width: 1000px !important;
    width: 100%;
    .mat-dialog-container {
      max-width: 1000px !important;
      width: 100%;
      height: 100vh;
      background: transparent;
      box-shadow: none;
    }
  }
}

@media screen and (max-width: 767px) {
  html,
  body {
    font-size: 15px;
    // min-height: calc(100% + 224px);
    // height: auto;
  }
  #main {
    min-width: 100%;
  }

  footer-share {
    #footer {
      min-width: 100%;
    }
  }

  .mobile-footer {
    display: block;
  }

  //TODO 1111 수정
  #footerWrap {
    display: none;
  }
  // #footerWrap {
  //   display: block;
  // }

  .mobile-footer {
    position: relative;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .service-txt {
    h4 {
      text-align: center;
    }
    p {
      text-align: center;
    }
  }

  .appintro-wrap {
    .top {
      flex-direction: column;
      margin-bottom: 140px;
    }
    .icon {
      margin-right: 0;
      margin-bottom: 60px;
    }
    .txt-wrap {
      h3 {
        margin-bottom: 20px;
        text-align: center;
        &::after {
          margin: 20px auto;
        }
      }
      p {
        text-align: center;
      }
    }
  }

  .explanation-txt {
    text-align: center;
  }

  /*
  *
  간편견적 팝업
  */
  .popup-wrap {
    max-width: 70% !important;
    width: 100%;
  }

  .btns-wrap {
    .btns-inner {
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 10px 110px;
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    button {
      width: 36%;
    }
  }
}

@media screen and (max-width: 550px) {
  html,
  body {
    font-size: 14px;
    // min-height: calc(100% + 224px);
    // height: auto;
  }
  .main-video,
  .main-bg {
    .main-text {
      padding: 0 10px;
    }
  }

  .btns-wrap {
    .prev-btn {
      padding-left: 3.8889rem;
    }

    .next-btn {
      padding-right: 3.8889rem;
    }

    .btns-inner {
      padding: 10px 140px;
    }
    button {
      width: 32%;
      background-size: 2.7778rem 2.7778rem;
    }

    p {
      font-size: 1.1rem;
      color: #fff;
      line-height: 30px;
    }
  }
}
// width 1180px
.w1180 {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto 200px;
}
// width 1280px
.w1280 {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}
// section title
.section-title {
  margin-bottom: 100px;
  h2 {
    margin-bottom: 1.12rem;
    font-size: 2.6667rem;
    font-weight: 800;
    line-height: 3.6rem;
    word-break: keep-all;
  }
  p {
    font-size: 1.166rem;
    line-height: 1.778rem;
    word-break: keep-all;
    span {
      font-size: 1.166rem;
    }
  }
}
// **************** design & marketing & inquiry & portfolio **************** //

// ***** service-menu
@media screen and (max-width: 1023px) {
  #serviceHeader #header,
  #serviceHeader #mHeader {
    position: relative;
    left: 0;
    top: 0;
    background: #000;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

///////mat-card reset
.mat-card {
  .mat-card-header {
    padding: 0;
    margin: 0;
    .mat-card-header-text {
      margin: 0;
      padding: 0;
      .mat-card-title {
        margin: 0;
        padding: 0;
      }
    }
  }

  .mat-card-actions,
  .mat-card-subtitle,
  .mat-card-content {
    margin: 0;
    margin-bottom: 0;
  }
  .mat-card-actions {
    margin: 0;
    padding: 0;
    .mat-button-toggle {
      border-radius: 0;
    }
    span {
      padding: 0;
    }
  }
}
.mat-card > .mat-card-actions:last-child {
  margin-bottom: 0 !important;
}

#header {
  &.on {
    top: 0;
    transition: top ease-in-out 0.2s;
  }
  &.off {
    top: -56px;
    transition: top ease-in-out 0.2s;
  }
}

.mat-dialog-container {
  padding: 0 !important;
}

.popup-wrap {
  max-width: 500px;
  min-width: 479px;
  width: 100%;

  .mat-dialog-container {
    padding: 0;
    background: transparent;
    box-shadow: none;
    border-radius: 0;
  }
}
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.7);
}

.load-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 990;
  display: flex;
  background: rgba(0, 0, 0, 0.9);
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  border-radius: 24px;
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.2s;
  &.active {
    width: 300px;
    height: 170px;

    opacity: 1;
    transition: opacity ease-in 0.2s;
  }

  .logo-icon {
    width: 62px;

    height: auto;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .loading-icon {
    width: 40px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: #07194e;
  }
}

a {
  cursor: pointer;
}

.buy-modal {
  overflow: scroll;
  max-height: calc(100% - 40px);
  .mat-dialog-container {
    padding: 0 !important;
  }
}

.terms-modal {
  max-width: unset !important;
  overflow: auto;
  .mat-dialog-container {
    padding: 0;
  }
}

// 스타터팩 > 상품 자세히 보기 팝업
.solution-popup {
  max-width: unset !important;
  overflow: auto;
  padding-block: 120px;
  .mat-dialog-container {
    border-radius: 28px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #0a0d15;
    margin-bottom: 16px;
    span {
      font-weight: 900;
    }
  }

  table {
    border-collapse: collapse;
    border-block: 2px solid #0a0d15;
  }
  caption {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: #0a0d15;
    padding-left: 12px;
    margin-bottom: 12px;
    span {
      font-weight: 900;
    }
  }

  th,
  td {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    // border-bottom: 1px solid #d2d6dd;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 1px;
      background: #d2d6dd;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .th {
    color: #3e3f44;
    border-right: 1px solid #0a0d15;
    padding: 16px 20px;
  }

  td {
    color: #0a0d15;
  }

  thead {
    tr {
      &:first-child {
        th {
          &:nth-of-type(1) {
            &:after {
              display: none;
            }
            border-bottom: 1px solid #0a0d15;
          }
          &:nth-of-type(2) {
            background-color: #f3f5f9;
          }
          &:nth-of-type(3) {
            background-color: #e9edf5;
            border-left: 1px solid #3d3d3d;
          }
        }
        th[colspan] {
          padding: 16px 20px;
        }
      }
      &:last-child {
        border-bottom: 1px solid #0a0d15;
        th {
          font-weight: 700;
          border-bottom: 0;
          padding: 19px 20px 20px;
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .product-title {
    font-weight: 400;
    color: #747579;
    margin-bottom: 4px;
  }

  .item-title {
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
  }

  tbody {
    td {
      padding: 20px;
      & > div {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
    }
  }

  // 특징
  .feature-line {
    span {
      font-weight: 900;
    }
  }

  .popover-area {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    width: 20px;
    height: 20px;
    background: url(./assets/imgs/detailRenewal/popover-icon.png) no-repeat
      center center / 16px;
    margin-left: 6px;
  }

  // 개월별 요금
  .price-line {
    td {
      position: relative;
    }
    div {
      row-gap: 4px;
    }
    p {
      &:nth-child(1) {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        span {
          font-size: 14px;
          font-weight: 400;
        }
      }
      &:nth-child(2) {
        font-size: 12px;
        line-height: 19px;
        color: #747579;
      }
    }
    .badge {
      position: absolute;
      top: 4px;
      left: 4px;
      font-size: 14px !important;
      line-height: 18px !important;
      border: 1px solid #586bff;
      border-radius: 4px;
      padding: 3px 6px;
    }
  }
  .quarter-year,
  .half-year {
    .th {
      border-bottom: 0;
      &::after {
        display: none;
      }
    }
  }
  .half-year {
    .badge {
      color: #586bff;
    }
  }
  .one-year {
    .th {
      background-color: #fff;
      color: #586bff;
    }
    p {
      &:nth-child(1) {
        color: #586bff;
        font-weight: 800;
        span {
          color: #0a0d15;
        }
      }
      &:nth-child(2) {
        color: #0a0d15;
      }
    }
    td {
      background-color: rgba(88, 107, 255, 0.1);
      .badge {
        color: #fff !important;
        background-color: #586bff;
      }
      &:nth-of-type(3),
      &:nth-of-type(5) {
        border: 2px solid #586bff;
      }
    }
  }

  // 신청 및 사용 방법
  .request-line {
    border-bottom: 2px solid #0a0d15;
    td {
      &:after {
        display: none;
      }
      &[colspan] {
        padding: 32px 20px;
      }
      & > div {
        display: grid;
      }
      .step-item {
        &:not(:last-child) {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 24px;
            right: 0;
            transform: translateX(50%);
            display: block;
            width: 28px;
            height: 4px;
            background: url(./assets/imgs/detailRenewal/dot-icon01.png)
              no-repeat center center / 100%;
          }
        }
      }
      .icon {
        width: 52px;
        height: 52px;
        border: 2px solid #586bff;
        border-radius: 50%;
        background: no-repeat center center / 24px;
        margin-inline: auto;
        margin-bottom: 16px;
      }
      .step-title {
        font-weight: 700;
        margin-bottom: 16px;
      }
      li {
        color: #3e3f44;
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }

      // 설치형 상품 행
      // &:nth-of-type(2) {
      &.way-install {
        vertical-align: top;
        & > div {
          grid-template-columns: repeat(3, 1fr);
        }
        .step-item {
          &:nth-child(1) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon01.png);
            }
          }
          &:nth-child(2) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon02.png);
            }
          }
          &:nth-child(3) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon04.png);
            }
          }
        }
      }

      // 스타터팩구독 행
      // &:nth-of-type(3) {
      &.way-store {
        vertical-align: top;
        & > div {
          grid-template-columns: repeat(5, 1fr);
        }
        .step-item {
          &:nth-child(1) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon01.png);
            }
          }
          &:nth-child(2) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon02.png);
            }
          }
          &:nth-child(3) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon04.png);
            }
          }
          &:nth-child(4) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon03.png);
            }
          }
          &:nth-child(5) {
            .icon {
              background-image: url(./assets/imgs/detailRenewal/step-icon04.png);
            }
          }
        }
      }
    }
  }

  // 안내사항 영역
  .guidelines {
    td {
      &[colspan] {
        padding: 29px 34px;
      }
    }

    li {
      list-style: disc;
      font-weight: 700;
      text-align: left;
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
    p {
      font-weight: 400;
      color: #3e3f44;
      &:first-child {
        margin-top: 8px;
        margin-bottom: 4px;
      }
    }
  }
  .attention-note {
    td {
      &::after {
        display: none;
      }
    }
    li {
      font-weight: 400;
    }
  }

  .guide-text {
    font-size: 12px;
    line-height: 19px;
    color: #929599;
    margin-top: 16px;
  }

  // 스타터팩
  .ownership-table {
    tr {
      .just {
        font-weight: 400;
        color: #929599;
      }
    }

    .price-line {
      td {
        &:not(.th) {
          color: #586bff;
        }
      }
    }

    .request-line {
      td {
        &.way-install {
          .step-item {
            &:nth-child(3) {
              .icon {
                background-image: url(./assets/imgs/detailRenewal/step-icon05.png);
              }
            }
          }
        }
      }
    }
  }
}

.solution-image-popup {
  width: 100%;
  max-width: 1136px !important;
  // overflow: auto;
  // padding-block: 120px;
  .mat-dialog-container {
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
}

.pop-up-backdrop {
  display: none;
}

.pop-up-dialog {
  // max-width: 95vw !important;
  // max-height: 133.33vw !important;
  // min-height: 450px;

  overflow: scroll;

  .mat-dialog-container {
    padding: 0 !important;
    background: transparent;
  }
}

.sample-pop-up {
  width: 100%;
  max-width: calc(100vw - 40px) !important;
  overflow: scroll;
  border-radius: 10px;

  .mat-dialog-container {
    padding: 0 !important;
    background: transparent;
  }
}

.sample-pop-up-backdrop {
  background: #000;
  opacity: 0.7 !important;
}

.pop-pagination-wrap {
  max-width: 600px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5.4375rem;
  background: transparent !important;
  .swiper-pagination {
    width: 48px;
    height: 20px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    margin: 0;
    .swiper-pagination-bullet {
      width: 8px !important;
      height: 8px !important;
      background: rgba(255, 255, 255, 0.6) !important;
      margin: 0;
    }
    .swiper-pagination-bullet-active {
      background: #fff !important;
    }
  }

  .mat-dialog-container {
    background: transparent !important;
  }
}
.home-banner-swiper {
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0;
  }
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 13px;
  }
  .swiper-pagination {
    width: 100%;
    margin-bottom: 0;
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #999999;
      opacity: 1;
      + .swiper-pagination-bullet {
        margin-left: 8px;
      }
    }
    .swiper-pagination-bullet-active {
      background: #fff;
      opacity: 1;
    }
  }
}
.mat-button-toggle {
  &-checked {
    background-color: #262832 !important;
    button {
      border: 1px solid #fff;
    }
    .mat-button-toggle-label-content {
      line-height: 1.875rem;
      color: #fff;
    }
  }
  &:hover {
    .mat-button-toggle-focus-overlay {
      opacity: 0 !important;
    }
  }
  &:first-child {
    border-radius: 8px 0 0 8px;
    button {
      border-radius: 8px 0 0 8px;
    }
  }
  &:last-child {
    border-radius: 0 8px 8px 0;
    button {
      border-radius: 0 8px 8px 0;
    }
  }
  button {
    height: 49px;
  }
  .mat-button-toggle-label-content {
    font-size: 18px;
    font-weight: 700;
    line-height: 2rem;
  }
}
app-solution-home {
  .mat-button-toggle {
    background-color: #fff !important;
    border: 1px solid #181920;

    &-checked {
      background-color: #181920 !important;

      .mat-button-toggle-label-content {
        line-height: 1.875rem;
        color: #fff !important;
      }

      button {
        border: 0;
      }
    }
    &:hover {
      .mat-button-toggle-focus-overlay {
        opacity: 0 !important;
      }
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
      button {
        border-radius: 8px 0 0 8px;
      }
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
      button {
        border-radius: 0 8px 8px 0;
      }
    }
    button {
      height: 49px;
    }
    .mat-button-toggle-label-content {
      font-size: 18px;
      font-weight: 700;
      line-height: 2rem;
      color: #0a0d15;
    }
  }
}

mat-accordion.mat-accordion {
  display: inline-block;
  width: 100%;
  .mat-expansion-panel {
    width: 100%;

    &:first-of-type,
    &:last-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not([class*="mat-elevation-z"]) {
      background-color: transparent;
      box-shadow: none;
      margin: 0;
    }

    // 열렸을 때
    &.mat-expanded {
    }

    // 모바일에서 멋없게 뜨는걸 없애고 싶어서 호버 효과를 지웠습니다.
    &:not(.mat-expanded)
      .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
      // background: transparent;
      // background-color: #333845;
    }
    // 제목 우측 화살표
    .mat-expansion-indicator::after {
      padding: 0;
      border: 0;
      transform: rotate(0);
      background: no-repeat center / 100% 100%;
      // 사이즈와 백그라운드로 화살표 이미지만 넣어서 사용하면 됩니다!
      // width: 24px;
      // height: 24px;
      // background-image: url();
    }

    // 헤더 높이 초기화, 헤더 css
    mat-expansion-panel-header {
      height: auto; // 헤더 높이 css
      border-radius: 0;
      box-sizing: border-box;
      padding: 0;
      .mat-expansion-panel-header-title {
        // 헤더 글씨 css
        color: #161616; // 글씨에 투명도가 있어서 적어놨습니다.
      }
    }
    .mat-expansion-panel-body {
      // 페널 내용 css
      padding: 0;
    }
  }
}

// 스타터팩상세 리뉴얼 페이지
app-solution-detail-renewal,
app-solution-home {
  mat-accordion.mat-accordion {
    border-block: 1px solid #b9babc;

    .mat-expansion-panel {
      border-bottom: 1px solid #747579;
      &:last-child {
        border-bottom: 0;
      }
      &.mat-expanded {
        .mat-expansion-panel-header {
          &:focus,
          &:hover {
            background-color: #333845;
          }
        }
      }

      .mat-expansion-indicator::after {
        width: 24px;
        height: 24px;
        background-image: url(./assets/imgs/detailRenewal/bottom-arrow.png);
      }
    }

    .mat-expansion-panel-header {
      // height: 72px;
      background-color: #333845 !important;
      padding: 20px;
    }

    .mat-expansion-panel-header-title {
      font-size: 24px;
      font-weight: 400;
      line-height: 32px;
      color: #fff !important;
    }

    .panel-content {
      display: flex;
      align-items: center;
      column-gap: 20px;
      font-size: 18px;
      line-height: 32px;
      color: #fff;
      background-color: #2c303b;
      border-bottom: 1px solid #41444d;
      padding: 20px 16px;

      &:first-child {
        padding-top: 28px;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 28px;
      }

      .content-title {
        min-width: 124px;
        font-weight: 700;
      }

      .content-desc {
        font-weight: 400;
        white-space: pre-line;
      }
    }

    // 자주 묻는 질문 accordion
    &.white-accordion {
      border-block-color: #3d3d3d;
      .mat-expansion-panel {
        border-bottom: 1px solid #d2d6dd;
        &.mat-expanded {
          .title-wrap {
            p {
              &:first-child {
                color: #586bff;
              }
              &:last-child {
                font-weight: 700;
              }
            }
          }
        }
      }
      .mat-expansion-panel-header {
        height: auto;
        background-color: #fff !important;
        padding: 16px 20px;
      }
      .mat-expansion-indicator::after {
        width: 24px;
        height: 24px;
        background-image: url(./assets/imgs/detailRenewal/bottom-arrow-gray.png);
      }
      .title-wrap {
        display: flex;
        align-items: flex-start;
        column-gap: 12px;
        color: #0a0d15;
      }
      p {
        font-size: 21px;
        line-height: 32px;
        color: #0a0d15;
        &:first-child {
          font-weight: 900;
          color: #b9babc;
        }
      }
      .panel-content {
        align-items: stretch;
        column-gap: 12px;
        background-color: #f3f5f9;
        padding: 32px 20px;
      }
    }
  }
}

.popover {
  max-width: 289px;
  margin-left: -16px;
  z-index: 10;
  .arrow {
    position: relative;
    z-index: 1002;
    width: 16px;
    height: 16px;
    background: url(./assets/imgs/detailRenewal/popover-arrow.png) no-repeat
      center center / 16px;
    transform: translateY(1px);
  }

  &.popover-right {
    margin-left: unset;
    margin-right: -16px;
    .arrow {
      right: 2px;
      left: unset;
      margin-left: auto;
    }
    .popover-body {
      transform: translateX(18px);
    }
  }

  &.popover-center {
    margin-left: unset;
  }
}
.popover-body {
  font-size: 14px;
  line-height: 20px;
  color: #0a0d15;
  text-align: left;
  background-color: #f3f5f9;
  border: 1px solid #d2d6dd;
  border-radius: 12px;
  padding: 12px 16px;
}

@media screen and (max-width: 1200px) {
  // 스타터팩팝업
  .solution-popup {
    max-width: calc(100% - 80px) !important;
    h2 {
      margin-bottom: 46px;
    }

    .guide-text {
      &:not(:last-child) {
        margin-bottom: 80px;
      }
    }

    table {
      width: 100%;
    }

    mat-accordion.mat-accordion {
      display: block;

      .mat-expansion-indicator::after {
        width: 24px;
        height: 24px;
        background-image: url(./assets/imgs/detailRenewal/bottom-arrow-blk.png);
      }

      .mat-expansion-panel-header {
        background-color: #fff !important;
        border-bottom: 2px solid #0a0d15;
        padding: 0 12px 12px;
      }
      .mat-expansion-panel-header-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        span {
          font-weight: 900;
          padding-right: 4px;
        }
      }

      .mat-expansion-panel-body {
        padding: 20px 12px 20px 26px;
      }
    }
    .attention-note {
      margin-top: 80px;
    }

    .request-line {
      td {
        li {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  // 상세페이지 - 관리자페이지 상세 이미지 팝업
  .solution-image-popup {
    // max-width: calc(100% - 48px) !important;
    max-width: 100% !important;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .buy-modal {
    width: calc(100% - 32px);
  }
  .pop-up-backdrop {
    display: block;
    background: rgba(0, 0, 0, 0.6);
  }

  .solution-popup {
    max-width: 100% !important;
    height: 100%;
    padding-block: 0;
    .mat-dialog-container {
      border-radius: 0;
    }

    mat-accordion.mat-accordion {
      .mat-expansion-panel-header {
        padding: 0;
        padding-bottom: 10px;
      }
      .mat-expansion-panel-header-title {
        font-size: 18px;
      }

      .mat-expansion-panel-body {
        padding: 20px 0 0 14px;
      }
    }
  }

  .mobile-table {
    h2 {
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      background-color: #fff;
      padding: 12px 16px;
      margin-bottom: 0;
    }

    #table-wrap {
      padding: 32px 16px 80px;
    }
    .table-group {
      padding-bottom: 48px;
    }
    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      border-bottom: 2px solid #0a0d15;
      padding-bottom: 10px;
      margin-bottom: 32px;
      span {
        font-weight: 900;
      }
    }

    table {
      border-top: 1px solid #0a0d15;
      border-bottom: 0;
      margin-bottom: 32px;
    }
    caption {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      color: #3e3f44;
      padding-left: 0;
      margin-bottom: 12px;
    }
    th,
    td {
      padding-inline: 2px !important;
      &:not(:last-child) {
        border-right: 1px solid #d2d6dd;
      }
    }

    .product-table {
      th {
        background-color: #f3f5f9;
        border-color: #d2d6dd;
      }
    }

    .service-table {
      th {
        background-color: #fff !important;
        &:first-child {
          border-right-color: #0a0d15;
        }
      }
    }

    .price-table {
      margin-bottom: 16px;
      thead {
        th {
          background-color: #fff;
          padding-block: 6px;
          &:first-child {
            width: 50px;
          }
          &:not(:last-child) {
            border-right-color: #0a0d15;
          }
        }
      }
      .th {
        padding: 0 2px;
      }
      .badge {
        position: static;
        width: fit-content;
        margin: 0 auto 4px;
      }
      td {
        &:not(.th) {
          padding: 20px 0;
        }
      }
    }
    .price-line {
      .th {
        border-bottom: 1px solid #d2d6dd;
      }
    }

    .guide-text {
      margin-bottom: 32px !important;
    }

    #table-wrap {
      .request-line {
        td {
          &.way-common {
            padding: 20px 0;
            & > div {
              grid-template-columns: 1fr;
              grid-auto-rows: 124px 124px auto;
              row-gap: 0;
            }
          }
          &.way-store {
            & > div {
              // grid-auto-rows: 124px 124px 124px auto;
            }
          }
        }
      }
      .step-item {
        display: flex;
        column-gap: 12px;
        text-align: left;
        &:first-child {
          .step-title {
            padding-block: 12px;
          }
        }
        &:not(:last-child) {
          &::after {
            top: 67px;
            right: unset;
            left: 20px;
            transform: rotate(90deg) translateY(-50%);
            transform-origin: 0 center;
          }
        }
        .step-title {
          margin-bottom: 8px;
        }
      }
      .way-store {
        .step-item {
          &:nth-child(3) {
            height: 124px;
            .step-title {
              padding-block: 12px;
            }
          }
        }
      }
      .icon {
        width: 44px;
        height: 44px;
        margin: unset;
      }
    }
  }

  // 스타터팩 팝업 모바일
  .ownership-mobile {
    .product-table {
      th {
        &:first-child {
          background-color: #f3f5f9;
          border-right-color: #0a0d15;
        }
        &:last-child {
          background-color: #e9edf5 !important;
        }
      }
    }
    .just {
      font-weight: 400 !important;
      color: #929599;
    }
    .price-line {
      p {
        font-weight: 900;
        color: #586bff;
      }
    }
  }

  app-solution-detail-renewal,
  app-solution-home {
    mat-accordion.mat-accordion {
      .mat-expansion-panel-header {
        padding: 16px 12px;
      }
      .mat-expansion-panel-header-title {
        font-size: 18px;
        line-height: 27px;
      }
      .panel-content {
        flex-direction: column;
        align-items: flex-start;
        row-gap: 8px;
        font-size: 16px;
        line-height: 24px;
        padding: 20px 12px;
        &:first-child {
          padding-top: 24px;
        }
        &:last-child {
          padding-bottom: 24px;
        }
      }

      &.white-accordion {
        p {
          font-size: 16px;
          line-height: 24px;
        }
        .panel-content {
          flex-direction: row;
        }
      }
    }
  }

  // 상세페이지 - 관리자페이지 상세 이미지 팝업
  .solution-image-popup {
    // max-width: calc(100% - 32px) !important;
    max-width: 100% !important;
  }
}

.app-pop-modal {
  max-width: 500px !important;
  width: 100%;
  .mat-dialog-container {
    max-width: 500px !important;
    width: 100%;
    height: 100vh;
    background: transparent;
    box-shadow: none;
  }
}

.toggle-segment {
  .mat-button-toggle {
    button {
      height: 100% !important;
      border: 0;
    }

    .mat-button-toggle-label-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}

.toggle-category {
  .mat-button-toggle {
    button {
      height: 100% !important;
      border: 0;
    }

    .mat-button-toggle-label-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}

@media screen and (max-width: 450px) {
  .app-pop-modal {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    .mat-dialog-container {
      padding: 0;
      border-radius: 0;
    }
  }
  .admin-sample-modal {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    min-height: 100% !important;
    .mat-dialog-container {
      padding: 0;
      border-radius: 0;
    }
  }

  .pop-pagination-wrap {
    bottom: 2.63rem !important;
  }

  @media screen and (min-height: 1000px) {
    .pop-pagination-wrap {
      bottom: 4.71rem !important;
    }
  }
}

// @media screen and (max-width: 375px) {
//   .app-pop-modal {
//     max-width: 100% !important;
//     max-height: 100% !important;
//     min-height: 100%;
//     min-width: 100%;
//     width: 100%;
//     height: 100%;
//     padding-top: 44px !important;
//     .mat-dialog-container {
//       padding: 0;
//       overflow: visible;
//     }
//   }
// }
// @media screen and (max-height: 812px) {
//   .app-pop-modal {
//     height: 100%;
//     padding-top: 44px;
//   }
// }

.inquiry-popup {
  width: 100%;
  max-width: 682px !important;
  overflow: auto;
  padding-block: 120px;

  .mat-dialog-container {
    border-radius: 12px;
  }
}

// alert
.swal2-container.swal2-backdrop-show {
  padding: 16px;
}
.swal2-popup {
  &.alert-custom {
    &.notext {
      .swal2-icon {
        margin-bottom: 12px !important;
      }
    }
    &.ok-btn {
      .swal2-actions {
        .swal2-styled {
          width: 100%;
        }
      }
    }
    width: 466px;
    // height: 292px;
    border-radius: 12px;
    padding-bottom: 24px;
    .swal2-title {
      font-family: "NanumSquare", sans-serif;
      min-height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      line-height: 36px;
      font-weight: 400;
      color: #161616;
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .swal2-html-container {
      font-family: "NanumSquare", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 21px;
      line-height: 32px;
      font-weight: 400;
      color: #63676d;
      margin: 0;
    }
    .swal2-icon {
      width: 60px;
      height: 60px;
      border: 0;

      margin-bottom: 24px;
      background: url(./assets/imgs/alert-icon.png) no-repeat center/100%;
      .swal2-icon-content {
        display: none;
      }
    }

    .swal2-actions {
      width: 100%;
      margin-top: 0;
      padding: 36px 24px 0;
      gap: 12px;
      display: flex !important;
      .swal2-styled {
        margin: 0;
        width: calc(50% - 6px) !important;
        height: 56px;
        font-family: "NanumSquare", sans-serif;
        font-size: 18px;
        line-height: 27px;
        font-weight: 700;
        &:focus {
          box-shadow: none;
        }
        &.swal2-cancel {
          color: #0a0d15;
          background: #e9e9eb;
        }
        &.swal2-confirm {
          color: #fff;
          background: #586bff;
          order: 2;
        }
      }
    }
  }
}

.page06 {
  //progress upload
  .progress-upload {
    .mbsc-ios.mbsc-form {
      background: transparent;
      .mbsc-form-group {
        margin: 0;
        .mbsc-ios.mbsc-progress {
          padding: 0;
          min-height: 16px;
          &::before {
            display: none;
          }
          &::after {
            display: none;
          }
        }
        .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-progress-value-right
          .mbsc-input-wrap,
        .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-progress-value-left
          .mbsc-input-wrap {
          padding-right: 0;
          padding-left: 0;

          .mbsc-progress-cont {
            padding-top: 8px;
            padding-bottom: 0px;
          }
        }
        .mbsc-progress-value {
          width: 0;
        }
      }
    }
    .mbsc-ios.mbsc-form .mbsc-progress,
    .mbsc-ios.mbsc-input-box.mbsc-progress,
    .mbsc-ios.mbsc-input-outline.mbsc-progress {
      background: #3b4253;
    }
    .mbsc-ios .mbsc-progress-track {
      background: #4f5667;
      border-radius: 2px;
      height: 4px;
      max-width: 228px;
    }
    .mbsc-ios .mbsc-progress-bar {
      background: #7282fc;
    }
  }
}

@media screen and (max-width: 767px) {
  .inquiry-popup {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";
